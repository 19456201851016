import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { SubSink } from 'subsink';
import { StoreLocalStorageAuthService } from '@auth/store-localStorage-auth.service';
import { AuthService } from '@auth/auth.service';
import { AlertService } from '@core/services/alert.service';
import { Store } from '@ngrx/store';
import { GlobalApiActions, GlobalSelectors } from '@core/stateGlobal';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { GraphService } from '@core/services/graph.service';
import { ProfileType } from '@core/models/utils.model';
//import { WebsocketService } from './notificaciones-ws/services/websocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  private readonly store = inject(Store);
  private authService = inject(MsalService);
  private msalBroadcastService = inject(MsalBroadcastService);
  private _translateService = inject(TranslateService);
  public primeNGConfig = inject(PrimeNGConfig);
  private _location = inject(Location);
  private storeService = inject(StoreLocalStorageAuthService);
  public _authService = inject(AuthService);
  private _alertService = inject(AlertService);
  public environment = environment.production;
  private router = inject(Router);
  private readonly _destroying$ = new Subject<void>();

  private _graphService = inject(GraphService);

  //private readonly _wsService = inject(WebsocketService);

  isIframe = false;
  locationInitialized: string;

  constructor() {
    (window as any).pdfWorkerSrc = '/assets/js/pdf.worker.min.js';

    this.locationInitialized = this._location.path();

    localStorage.removeItem('list-scopes');

    //Limpiar cache y unregister service worker PWA
    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();

          setTimeout(function () {
            console.log('trying redirect do');
            window.location.replace(window.location.href); // because without redirecting, first time on page load: still service worker will be available
          }, 3000);
        }
      });
    }
  }

  ngOnInit(): void {
    //this._wsService.resetChannel();

    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.subs.add(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
          )
        )
        .subscribe((result: EventMessage) => {
          if (this.authService.instance.getAllAccounts().length === 0) {
            window.location.pathname = '/login';
          } else {
            this.setLoginDisplay();
          }
        }),

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        }),

      this._translateService.stream('primeng-es').subscribe((data) => {
        this.primeNGConfig.setTranslation(data);
      })
    );
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.store.dispatch(GlobalApiActions.initLoadResorces());

      const loadingUserSession$ = this.store.select(GlobalSelectors.selectUserSession);

      this.subs.add(
        loadingUserSession$.subscribe(({ profileGraph, profileToken }) => {
          if (profileGraph && profileToken) {
            this.store.dispatch(GlobalApiActions.initListChostAllCompany({ email: profileGraph.userPrincipalName }));

            this.storeService.setDataToGetNewToken(profileToken);

            //Arrancar el socket en el canal del usuario -- Aun esta en pruebas
            //this._wsService.joinChannel(`CRM_${profileGraph.id}_${environment.production ? 'PROD' : 'DEV'}`);

            this.subs.add(
              this._authService.loginApiToken(profileToken).subscribe((isLogin) => {
                if (!isLogin) this._alertService.error('Error al iniciar sesión en la aplicación Azure-CRM');
              })
            );
          }
        }),

        this._graphService.getDataMeProfile().subscribe((profile: ProfileType) => {
          const profileToken = {
            uniqueId: profile.id,
            username: profile.userPrincipalName,
            name: profile.displayName,
            environment: 'login.app.web',
          };

          this.storeService.setDataToGetNewToken(profileToken);
        })
      );
    } else this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
