import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FailedComponent } from './shared/failed/failed.component';
import { BrowserUtils } from '@azure/msal-browser';
import { GenerateIcsComponent } from './generate-ics/generate-ics.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuditLogComponent } from './audit-log/audit-log.component';

import { LoginComponent } from '@auth/login/login.component';
import { LayoutComponent } from './container/layout/layout.component';
import { RolUserChildGuard } from '@core/guards/rol-user.guard';

const routes: Routes = [
  { path: 'create-ics-file/:id', component: GenerateIcsComponent },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MsalGuard, RolUserChildGuard],
    canActivateChild: [MsalGuard, RolUserChildGuard],
    canMatch: [MsalGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () => import('./usuario/usuario.module').then((m) => m.UsuarioModule),
      },
      {
        path: 'autos',
        loadChildren: () => import('./autos/autos.module').then((m) => m.AutosModule),
      },
      {
        path: 'transacciones',
        loadChildren: () => import('./transacciones/transacciones.module').then((m) => m.TransaccionesModule),
      },

      {
        path: 'citas', //soon deprecate
        loadChildren: () => import('./citas/citas.module').then((m) => m.CitasModule),
      },
      {
        path: 'publicaciones-v2/citas',
        loadChildren: () => import('./citas/citas.module').then((m) => m.CitasModule),
      },
      {
        path: 'credito',
        loadChildren: () => import('./credito/credito.module').then((m) => m.CreditoModule),
      },
      {
        path: 'calendariov2',
        loadChildren: () => import('./calendario-v2/calendario-v2.module').then((m) => m.CalendarioV2Module),
      },
      {
        path: 'publicaciones-v2',
        loadChildren: () =>
          import('./autos/publicaciones-v2/publicaciones-v2.module').then((m) => m.PublicacionesV2Module),
      },
      {
        path: 'mesa-control',
        loadChildren: () => import('./mesa-control/mesaControl.module').then((m) => m.MesaControlModule),
      },
      {
        path: 'referencias',
        loadChildren: () => import('./referencias/referencias.module').then((m) => m.ReferenciasModule),
      },
      {
        path: 'ajustes-admin',
        loadChildren: () => import('./ajustes-admin/ajustes-admin.module').then((m) => m.AjustesAdminModule),
      },
      {
        path: 'mis-asignados',
        loadChildren: () => import('./mis-asignados/mis-asignados.module').then((m) => m.MisAsignadosModule),
      },
      {
        path: 'calculadora',
        loadChildren: () => import('./calculadora/calculadora.module'),
      },
      /*{
        path: 'pagos',
        loadChildren: () => import('./pagos-deprecate/pagos.module'),
      },*/
      {
        path: 'solicitud-contrato',
        loadChildren: () => import('./contratos/solicitud/solicitud.module'),
      },
      {
        path: 'apartados',
        loadChildren: () => import('./apartados/apartados.module'),
      },
      {
        path: 'pagos-inspeccion',
        loadChildren: () => import('./pagos-inspeccion/pagos-inspeccion.module'),
      },
      { path: 'log-audit', component: AuditLogComponent },
      { path: 'home/dashboard', redirectTo: 'dashboard' },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled', // Remove this line to use Angular Universal
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
