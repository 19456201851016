import { Injectable, inject } from '@angular/core';

import { Actions } from '@ngrx/effects';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions) {}
  /*notifyApiError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(),
        tap((action) => this._notificationsService.error(action.errorMessage))
      );
    },
    { dispatch: false }
  );*/
}
